@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');

div {
  font-family: Epilogue, sans-serif;
  margin: 5vh auto;
}

body {
  background-image: url("/public/background.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
}

p {
  font-size: calc(3px + 2vmin);
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.image_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image {
  width: 50%;
}

.image.upload {
  border:solid white;  
  border-width: 10px 10px 40px 10px;
  transform: rotate(2deg);
}

.image.logo {
  filter: invert(1);
}

.button {
  font-family: Epilogue, sans-serif;
  background-color: white;
  border: none;
  text-align: center;
  font-size: 18px;
  padding: 10px;
}

.button:hover {
  background-color: grey;
  transition: 0.4s;
}

input[type=file]::file-selector-button {
  font-family: Epilogue, sans-serif;
  background-color: white;
  border: none;
  font-size: 18px;
  padding: 10px;
  margin-left: 26%;
  margin-bottom: 4vh;
}

input[type=file]::file-selector-button:hover {
  background-color: grey;
  transition: 0.4s;
}

input[type=file] {
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0);
  margin: 0 auto;
}